import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import ImageContainer from "../components/sections/ImageContainer"
import SEO from "../components/seo"
import { getValidCategoryProducts } from "../utils/methods"

const ProductType = ({ data }) => {
  const { category } = data.saleor

  const [validCategoryProducts, setValidCategoryProducts] = React.useState([])

  React.useEffect(() => {
    if (category.products) {
      setValidCategoryProducts(getValidCategoryProducts(category.products))
    }
  }, [category])

  return (
    <>
      <SEO
        title={`Products - ${category.name}`}
        path={`/products/${category.slug}/`}
      />
      {category?.backgroundImage?.url && (
        <ImageContainer src={category?.backgroundImage?.url} />
      )}
      <ProductList hasHeader={false} products={validCategoryProducts} />
    </>
  )
}

export const pageQuery = graphql`
  query ProductTypeBySlug($id: ID!) {
    saleor {
      category(id: $id) {
        id
        slug
        name
        backgroundImage {
          alt
          url
        }
        products(first: 100) {
          edges {
            node {
              id
              name
              slug
              visibleInListings
              isAvailable
              isAvailableForPurchase
              pricing {
                discount {
                  gross {
                    amount
                    currency
                  }
                }
              }
              category {
                name
              }
              variants {
                name
                id
                pricing {
                  price {
                    gross {
                      amount
                      currency
                    }
                  }
                }
              }
              thumbnail {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`

ProductType.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductType
